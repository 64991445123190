/* --------------------------------------------------------------- */

:root {
  --site-padding: 50px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 16px;
  user-select: none;
}

/* --------------------------------------------------------------- */

html, body {
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
}

/* --------------------------------------------------------------- */

.mr-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: url(img/meeting-app-background.png) rgb(7, 27, 39) no-repeat top right;
}

/* --------------------------------------------------------------- */

.mr-room {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: var(--site-padding);
  padding-bottom: 0;
}

.mr-room-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-height: 585px;
  min-height: 585px;
}

/* --------------------------------------------------------------- */

/* The actual meeting content. */
.mr-meeting {
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  border: 1px solid rgb(0, 170, 110);
  border-radius: 10px;
  border-width: 2px;
  border-left-width: 30px;
  padding-left: 50px;
  padding-top: 60px;
  margin-right: 80px;
  padding-bottom: 40px;
}

.mr-meeting.is-booked {
  border-color: rgb(240, 75, 65);
}

.mr-room.selected .mr-meeting.is-not-booked,
.mr-room.selected .mr-meeting.is-booked {
  background-color: white;
}

.mr-room.selected .mr-meeting.is-not-booked,
.mr-room.selected .mr-meeting-date,
.mr-room.selected .mr-meeting.is-not-booked .mr-meeting-spec,
.mr-room.selected .mr-meeting.is-not-booked .mr-meeting-spec strong,
.mr-room.selected .mr-meeting.is-booked,
.mr-room.selected .mr-meeting-date,
.mr-room.selected .mr-meeting.is-booked .mr-meeting-spec,
.mr-room.selected .mr-meeting.is-booked .mr-meeting-spec strong {
  color: black !important;
}

.mr-meeting h2 {
  font-size: 42px;
  font-weight: 300;
  margin-bottom: 100px;
}

.mr-meeting-specs {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  margin-bottom: 50px;
}

.mr-meeting-spec-organizer {
  margin-left: 50px;
}

.mr-meeting-spec,
.mr-meeting-icon {
  margin-right: 20px;
}

.mr-meeting-date,
.mr-meeting-spec,
.mr-meeting-icon,
.mr-meeting-spec strong {
  font-size: 28px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
}

.mr-meeting-spec strong {
  font-weight: bold;
}

.mr-meeting-icon svg {
  transform: scale(1.5);
}

.mr-meeting-date {
  margin-bottom: 20px;
}

.mr-meeting-desc {
  font-size: 70px;
  padding-left: 20px;
  padding-right: 30px;
  border-left: 1px solid rgb(158, 219, 216);
}

.mr-meeting-back-to-now {
  position: absolute;
  right: 50px;
  top: 55px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  display: none;
}

.mr-room.selected .is-not-booked .mr-meeting-back-to-now,
.mr-room.selected .is-booked .mr-meeting-back-to-now {
  display: block;
}

/* --------------------------------------------------------------- */

.mr-clock {
  font-size: 44px;
  color: rgb(156, 219, 217);
  font-weight: bold;
}

/* --------------------------------------------------------------- */

.mr-upcoming-meeting {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 100px;
  color: rgba(255, 255, 255, 0.5);
}

.mr-upcoming-meeting .mr-meeting-icon,
.mr-upcoming-organizer,
.mr-upcoming-duration,
.mr-upcoming-title {
  margin-right: 20px;
}

.mr-upcoming-meeting .mr-meeting-icon,
.mr-upcoming-organizer,
.mr-upcoming-duration,
.mr-upcoming-bookedby,
.mr-upcoming-title {
  font-size: 24px;
}

.mr-upcoming-duration {
  margin-right: 40px;
}

.mr-upcoming-meeting .mr-meeting-icon {
  filter: brightness(0) invert(1);
  opacity: 0.5;
  transform: scale(0.6);
  margin-right: 7px;
}

/* --------------------------------------------------------------- */

.mr-slots-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  min-height: 277px;
  width: 100%;
  padding-left: var(--site-padding);
  padding-right: var(--site-padding);
  background-color: rgb(156, 219, 217);
}

.mr-slots-interaction {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  max-height: 50px;
  margin-bottom: 20px;
}

.mr-slots-book-at,
.mr-slots-interaction .lc-filter-title,
.mr-filter-title {
  font-size: 24px;
}

.mr-slots-interaction .lc-filter-header {
  color: white;
}

/* The row with the slot items + next/prev buttons. */
.mr-slots-row {
  display: flex;
  flex-direction:  row;
  align-items: center;
  /* border: 5px solid red; */
}

.mr-slots-nav-button {
  cursor: pointer;
}

.mr-slots {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  /* border: 6px solid magenta; */
  overflow: hidden;
  padding: 8px;
}

.mr-slots-scroller {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.mr-slot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 70px;
  min-width: 128px;
  padding: 10px;
  border: 1px solid gray;
  margin-right: 10px;
  border: 3px solid rgba(0, 170, 110, 0.5);
  border-radius: 10px;
  color: rgba(0, 170, 110, 1.0);
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.mr-slot-booked {
  border: 3px solid rgba(240, 75, 65, 0.5);
  color: rgb(240, 75, 65)
}

.mr-slot-selected {
  background-color: white;
}

.mr-slots-book-at-icon {
  margin-right: 10px;
}

.mr-slots-book-at {
  margin-left: auto; /* Pushing to the end of the flex-direction (row, so to the right) */
}

/* --------------------------------------------------------------- */
/* HIDDEN ADMIN                                                    */
/* --------------------------------------------------------------- */
.mr-admin {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.mr-admin-event {
  display: block;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 40px;
  margin-bottom: 10px;
  background-color: rgb(1, 31, 46);
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  font-size: 24px;
}

.mr-admin-room {
  padding: 20px;
  margin-bottom: 10px;
  background-color: rgb(1, 31, 46);
  color: rgba(255, 255, 255, 0.5);

  border-radius: 40px;
}

.mr-admin-event,
.mr-admin-room {
  cursor: pointer;
  border: 3px solid transparent;
}

.mr-admin-close,
.mr-admin-room.selected,
.mr-admin-event.selected {
  font-weight: bold;
  color: rgba(255, 255, 255, 1.0);
  border: 3px solid rgb(95, 209, 223);
}

.mr-admin-close {
  position: absolute;
  right: 20px;
  top: 0px;
  padding: 15px 40px;
  border-radius: 30px;
  background-color: rgb(1, 41, 66);
  cursor: pointer;
}
