/* --------------------------------------------------------------- */
/* UI: Checkbox List                                               */
/* --------------------------------------------------------------- */

.lc-checkbox-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

label.lc-toggle-all-checkbox span {
  font-weight: bold !important;
}

/* --------------------------------------------------------------- */
/* UI: Filter                                                      */
/* --------------------------------------------------------------- */
.lc-filter {
  position: relative;
  cursor: pointer;
  user-select: none;
}

.lc-filter-modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0;
  z-index:8888;
}

.lc-filter-modal.open {
  display: block;
  position: fixed;
}

.lc-filter-content {
  position: absolute;
  z-index: 9999;
  top: 30px;
  left: 0px;
  display: none;
  padding: 25px;
  box-shadow: var(--box-shadow);
  border-radius: 15px;
  background-color: white;
}

/* Move the content up, above the header. */
.lc-filter-content.up {
  top: auto;
  bottom: 60px;
}

.lc-filter-header {
  display: inline-flex;
  flex-direction: row;
  padding: 20px;
  align-items: center; 
}

.lc-filter-title {
  margin-right: 5px;
  font-weight: normal;
  font-size: 14px;
}

.lc-filter-tick {
}

.lc-filter.open .lc-filter-header {
}

.lc-filter.open .lc-filter-content {
  display: inline-block;
}

.lc-filter li {
  list-style: none;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  padding: 10px 5px;
  font-size: 14px;
  font-weight: 300;
  color: rgb(153, 153, 153);
}

.lc-filter li.lc-filter-show-all {
  font-weight: bold;
}

/* --------------------------------------------------------------- */
/* UI: Clock                                                       */
/* --------------------------------------------------------------- */

.lc-clock {
  flex: 0;
  flex-direction: row;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box;
  min-height: 60px;
  height: 60px;
  width: 120px;
  min-width: 120px;
  padding-left: 20px;
  padding-right: 25px;
  margin-bottom: 60px;
  border-radius: 30px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.lc-clock span:first-of-type {
  margin-left: 10px;
}

/* --------------------------------------------------------------- */
/* UI: Text Link Button                                            */
/* --------------------------------------------------------------- */

/* Normal text link button. */
.lc-text-button {
  display: inline-block;
  font-size: 16px;
  color: rgb(8, 31, 44);
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(8, 31, 44);
  cursor: pointer;
}


/* Text button, to be used on dark backgrounds. */
.lc-dark-text-button {
  font-weight: 300;
  align-self: center; /* We use a LcStack that uses flex; so we need this. */
  display: inline-block;
  padding: 10px;
  border-bottom: 1px solid rgb(156, 219, 217);
  cursor: pointer;
  user-select: none;
  color: white;
}

/* --------------------------------------------------------------- */
/* UI: Logo's on the top right of the page.                        */
/* --------------------------------------------------------------- */

.lc-page-logo {
  position: absolute;
  top: 50px;
  right: 50px;
}

.lc-column-logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-right: 50px;
}

/* --------------------------------------------------------------- */
/* UI: Page Header                                                 */
/* --------------------------------------------------------------- */

.lc-page-header {
  position: relative;
  box-sizing: border-box;
  height: 175px;
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 50px;
  /* margin-bottom: 40px; */
}

/* --------------------------------------------------------------- */
/* UI: Modal Popup (full window)                                   */
/* --------------------------------------------------------------- */

.lc-modal {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 3, 44, 0.8);
  z-index: 999;
}

.lc-modal-confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: white;
  padding: 40px 20px;
  max-width: 525px;
  width: 525px;
  box-shadow: 0px 5px 20px rgba(8, 31, 44);
}

.lc-modal-confirm-text {
  max-width: 90%;
  text-align: center;
  line-height: 50px;
  margin-bottom: 30px;
}

.lc-modal-confirm strong,
.lc-modal-confirm span {
  font-size: 40px;
}

.lc-modal-confirm svg {
  margin-bottom: 30px;
}

.lc-modal-cancel-button {
  background-color: rgb(239, 239, 239) !important;
};

/* --------------------------------------------------------------- */
/* UI: Circular Progress                                           */
/* --------------------------------------------------------------- */

.lc-circular-progress {
  filter: brightness(0) invert(1);
  margin-left: 10px;
}
.lc-circular-progress svg {
  transform: scale(0.75) !important;
}
